import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer } from "@mui/material";
import LoginContext from "../context/login_context/LoginContext";

import MenuIcon from "@mui/icons-material/Menu";
import OutputOutlinedIcon from "@mui/icons-material/OutputOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import BarChartIcon from '@mui/icons-material/BarChart';
import SpeedIcon from '@mui/icons-material/Speed';
import LogoutIcon from '@mui/icons-material/Logout';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import RuleIcon from '@mui/icons-material/Rule';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AddHomeIcon from '@mui/icons-material/AddHome';
import TvIcon from '@mui/icons-material/Tv';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';

import "./main.css";

import { VERSION } from "../router/Url";
import { Make_report_temps } from "../API/MAKE/Make_report_temps.api";
import { Make_Report_Factures } from "../API/MAKE/Make_Report_Factures.api";
import { ModalElectricFile } from "./ModalElectricFile.comp";
import { GetStatusBroker } from "../API/OUR_Lecturas/GetStatusBroker.api";

let aut_tablaindicadores = [
  "jurzua@ariztia.com",
  "aalvarado@ariztia.com"
]

export const HeaderMenu = ({ Pag_Sel ,IsMonitorFijo}) => {
  const navigate = useNavigate();
  const { LoginState, SetLogin, CloseLogin, GetLoginSession } = useContext(LoginContext);
  // console.log(LoginState)//"developer"//.area.includes('IQF'))
  const [PageSelcected, setPageSelcected] = useState(Pag_Sel);
  const [IsHiddedn, setIsHiddedn] = useState(true);

  const [brokerStatus, setbrokerStatus] = useState([]);

  /** modal electric hidden/show */
  const [IsModalElectric, setIsModalElectric] = useState(false)

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandleCloseSession = async () => {
    CloseLogin();
    navigate("/");
  };

  let ShowModalDatosElectricos = () => {
    setIsModalElectric(true)
  }

  const Make_a_reprot_tempsPlanta = async () => {
    let IsMakeReport = Make_report_temps({empresa: LoginState.planta});
  }

  const Make_a_reprot_facturas = async () => {
    let IsMakeReport = Make_Report_Factures({empresa: LoginState.planta});
  }

  useEffect(() => {
    const GetStatus = async () => {
      let stat_us = await GetStatusBroker();
      // console.log(stat_us)
      setbrokerStatus(stat_us)
    }
    let statusB = setInterval(() => {
      GetStatus()    
    }, 1000*60);
    GetStatus()  
    return () => {
      clearInterval(statusB)
    }
  }, [])
  

  return (
    <>
      <div className="container-fluid header">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="ocean_2">
                <div className="wave_2"></div>
                <div className="wave_2"></div>
                <div className="wave_2"></div>
              </div>
              <div className="col-3 my-2">
                <img src={require("../assets/logo_main.png")} height={"50px"} />
              </div>
              <div className="col-9 p-auto m-auto">
                <div className="row">
                  <div className="col-10">
                    <div className="row">
                      <div className="col-12 hidden-xs col-md-6">
                        <b>{LoginState.planta}</b>
                      </div>
                      <div className="col-12 col-md-6 text-right">{LoginState.user}</div>
                    </div>
                  </div>
                  <div className="col-2 text-right p-auto m-auto">
                    <span className="menubtn" onClick={toggleDrawer("right", true)}>
                      <MenuIcon />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
        <div className="menu-container">
          <img src={require("../assets/logo_main.png")} style={{ height: "150px" }} className="px-5" />
          <hr />
          <div className="col text-center">
            {
              brokerStatus.length>0?
              brokerStatus.map((item, index) => {
                return (
                    <WbTwilightIcon
                      style={{marginRight: "10px"}}
                      key={item._id}
                      titleAccess={`${item.EMPRESA}; Ultimo Dato guardado: ${new Date(item.TS).toLocaleString()}` }
                      color={item.STATUS?"success":"disabled"}
                      fontSize="small"
                    />
                )
              }):null
            }
          </div>
          <div className="" onClick={toggleDrawer("right", false)}>
            <p
              className={PageSelcected === "dashboard" ? "menu-item selected-item" : "menu-item"}
              onClick={() => navigate("/dashboard")}>
              {/* <DashboardIcon className="color-icon-menu" /> */}
              <SpeedIcon className="color-icon-menu" />
              <span className="spacing"></span> DashBoard
            </p>
          </div>

          {
            
            LoginState.rol_==="developer"?<>

              {/* VISTAS CON DATOS PROPIOS */}

              <div className="" onClick={toggleDrawer("right", false)}>
                <p
                  className={PageSelcected === "/v2/temperaturas" ? "menu-item selected-item" : "menu-item"}
                  onClick={() => navigate("/v2/temperaturas")}>
                  {/* <DashboardIcon className="color-icon-menu" /> */}
                  <WorkspacePremiumIcon className="color-icon-menu" />
                  <span className="spacing"></span> /v2/temperaturas <BarChartIcon />
                </p>
              </div>

              <div className="" onClick={toggleDrawer("right", false)}>
                <p
                  className={PageSelcected === "electric_ioch" ? "menu-item selected-item" : "menu-item"}
                  onClick={() => navigate("/electric_ioch")}>
                  {/* <DashboardIcon className="color-icon-menu" /> */}
                  <WorkspacePremiumIcon className="color-icon-menu" />
                  <span className="spacing"></span> Electrico IOCH <BarChartIcon />
                </p>
              </div>

              <div className="" onClick={toggleDrawer("right", false)}>
                <p
                  className={PageSelcected === "/datosdecaldera" ? "menu-item selected-item" : "menu-item"}
                  onClick={() => navigate("/datosdecaldera")}>
                  {/* <DashboardIcon className="color-icon-menu" /> */}
                  <WorkspacePremiumIcon className="color-icon-menu" />
                  <span className="spacing"></span> Grafico Caldera <BarChartIcon />
                </p>
              </div>

              <div className="" onClick={toggleDrawer("right", false)}>
                <p
                  className={PageSelcected === "borrar" ? "menu-item selected-item" : "menu-item"}
                  onClick={() => navigate("/borrar")}>
                  {/* <DashboardIcon className="color-icon-menu" /> */}
                  <WorkspacePremiumIcon className="color-icon-menu" />
                  <span className="spacing"></span> Seguimiento <BarChartIcon />
                </p>
              </div>

              <div className="" onClick={toggleDrawer("right", false)}>
                <p
                  className={PageSelcected === "/v2/pozos" ? "menu-item selected-item" : "menu-item"}
                  onClick={() => navigate("/v2/pozos")}>
                  {/* <DashboardIcon className="color-icon-menu" /> */}
                  <WorkspacePremiumIcon className="color-icon-menu" />
                  <span className="spacing"></span> /v2/pozos <BarChartIcon />
                </p>
              </div>
              
              
              {/* Grafico con las lecturas almacenadas mediante broker propio */}
              <div className="" onClick={toggleDrawer("right", false)}>
                <p
                  className={PageSelcected === "polloenfermo" ? "menu-item selected-item" : "menu-item"}
                  onClick={() => navigate("/polloenfermo")}>
                  {/* <DashboardIcon className="color-icon-menu" /> */}
                  <WorkspacePremiumIcon className="color-icon-menu" />
                  <span className="spacing"></span> Pollo Enfermo <BarChartIcon />
                </p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='temperaturas'?'menu-item selected-item':'menu-item'}
                  onClick={()=>navigate('/temperaturas')}>
                  <WorkspacePremiumIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Grafico Temperaturas <BarChartIcon /> </p>
              </div>
              <div className="" onClick={toggleDrawer("right", false)}>
                <p
                  className={PageSelcected === "ourgrafictest" ? "menu-item selected-item" : "menu-item"}
                  onClick={() => navigate("/ourgrafictest")}>
                  {/* <DashboardIcon className="color-icon-menu" /> */}
                  <WorkspacePremiumIcon className="color-icon-menu" />
                  <span className="spacing"></span> Grafico Propio <BarChartIcon />
                </p>
              </div>
              <div className="" onClick={toggleDrawer("right", false)}>
                <p
                  className={PageSelcected === "ourgaugetest" ? "menu-item selected-item" : "menu-item"}
                  onClick={() => navigate("/ourgaugetest")}>
                  {/* <DashboardIcon className="color-icon-menu" /> */}
                  <WorkspacePremiumIcon className="color-icon-menu" />
                  <span className="spacing"></span> Gauge Propio <SpeedIcon />
                </p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='electric'?'menu-item selected-item':'menu-item'}
                  onClick={()=>navigate('/electric')}>
                  <BarChartIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Grafico Electrico ⚡ </p>
              </div>

              {/* boton modal electrico */}

              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='hidric'?'menu-item selected-item':'menu-item'}
                  onClick={()=>navigate('/hidric')}>
                  <BarChartIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Grafico Hidrico 💧 </p>
              </div>
              
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='tablacrud'?'menu-item selected-item':'menu-item'}
                  onClick={()=>navigate('/tablacrud')}>
                  <BarChartIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Indicadores Faena </p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='fijofijo'?'menu-item selected-item':'menu-item'}
                  onClick={()=>navigate('/fijofijo')}>
                  <TvIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Monitor Fijo</p>
              </div>
              
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='ctrl_temp'?'menu-item selected-item':'menu-item'}
                  onClick={()=>navigate('/ctrl_temp')}>
                  <BarChartIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Grafico Temperaturas 🌡</p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='ingreso_temp'?'menu-item selected-item':'menu-item'}
                  onClick={()=>navigate('/ingreso_temp')}>
                  <BarChartIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Ingreso</p>
              </div>
              
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='ingreso_temp'?'menu-item selected-item':'menu-item'}
                  onClick={()=>Make_a_reprot_tempsPlanta()}>
                  <FileOpenIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Reporte 🌡</p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='accesos'?'menu-item selected-item':'menu-item'}
                  onClick={()=>navigate('/accesos')}>
                  <FactCheckIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Control Acceso <RuleIcon/></p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='addubi'?'menu-item selected-item':'menu-item'}
                  onClick={()=>navigate('/addubi')}>
                  <AddHomeIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Agregar Ubicacion</p>
              </div>

              {/* factura */}
              <div className='' onClick={toggleDrawer('right', false)}>
                  <p
                    className={PageSelcected==='ifactura'?'menu-item selected-item':'menu-item'}
                    onClick={()=>navigate('/ifactura')}>
                  <ListAltIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Ingreso Facturas</p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                  <p
                    className={PageSelcected==='chartfactura'?'menu-item selected-item':'menu-item'}
                    onClick={()=>navigate('/chartfactura')}>
                  <BarChartIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Grafico Facturas</p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                  <p
                    className={PageSelcected==='viewfactura'?'menu-item selected-item':'menu-item'}
                    onClick={()=>navigate('/viewfactura')}>
                  <PlagiarismOutlinedIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Ver Facturas</p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                <p
                  className={PageSelcected==='ingreso_temp'?'menu-item selected-item':'menu-item'}
                  onClick={()=>Make_a_reprot_facturas()}>
                  <FileOpenIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Reporte Facturas</p>
              </div>
              {/* factura */}

              <div className='' onClick={toggleDrawer('right', false)}>
                  <p
                    className={PageSelcected==='icaldera'?'menu-item selected-item':'menu-item'}
                    onClick={()=>navigate('/icaldera')}>
                  <ListAltIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Ingreso Datos Caldera</p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                  <p
                    className={PageSelcected==='actualizalo'?'menu-item selected-item':'menu-item'}
                    onClick={()=>navigate('/actualizalo')}>
                  <ListAltIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Actualizar Pantalla Faena</p>
              </div>
              <div className='' onClick={toggleDrawer('right', false)}>
                  <p
                    className={PageSelcected==='riless'?'menu-item selected-item':'menu-item'}
                    onClick={()=>navigate('/riless')}>
                  <ListAltIcon className='color-icon-menu'/>
                  <span className='spacing'></span> Monitor Riles</p>
              </div>
            </>
            :<>
            {/* emellado */}
              {
                LoginState.rol==="emellado@ariztia.com"||LoginState.rol==="garos@ariztia.com"?<>
                  
                  <div className="" onClick={toggleDrawer("right", false)}>
                    <p
                      className={PageSelcected === "/v2/temperaturas" ? "menu-item selected-item" : "menu-item"}
                      onClick={() => navigate("/v2/temperaturas")}>
                      {/* <DashboardIcon className="color-icon-menu" /> */}
                      <WorkspacePremiumIcon className="color-icon-menu" />
                      <span className="spacing"></span> temperaturas <BarChartIcon />
                    </p>
                  </div>
                  
                  <div className="" onClick={toggleDrawer("right", false)}>
                    <p
                      className={PageSelcected === "electric_ioch" ? "menu-item selected-item" : "menu-item"}
                      onClick={() => navigate("/electric_ioch")}>
                      {/* <DashboardIcon className="color-icon-menu" /> */}
                      <WorkspacePremiumIcon className="color-icon-menu" />
                      <span className="spacing"></span> Electrico IOCH <BarChartIcon />
                    </p>
                  </div>

                  <div className="" onClick={toggleDrawer("right", false)}>
                    <p
                      className={PageSelcected === "/v2/pozos" ? "menu-item selected-item" : "menu-item"}
                      onClick={() => navigate("/v2/pozos")}>
                      {/* <DashboardIcon className="color-icon-menu" /> */}
                      <WorkspacePremiumIcon className="color-icon-menu" />
                      <span className="spacing"></span> Pozos <OilBarrelIcon />
                    </p>
                  </div>
                  <div className="" onClick={toggleDrawer("right", false)}>
                    <p
                      className={PageSelcected === "polloenfermo" ? "menu-item selected-item" : "menu-item"}
                      onClick={() => navigate("/polloenfermo")}>
                      {/* <DashboardIcon className="color-icon-menu" /> */}
                      <WorkspacePremiumIcon className="color-icon-menu" />
                      <span className="spacing"></span> Pollo Enfermo <BarChartIcon />
                    </p>
                  </div>
                  <div className='' onClick={toggleDrawer('right', false)}>
                    <p
                      className={PageSelcected==='temperaturas'?'menu-item selected-item':'menu-item'}
                      onClick={()=>navigate('/temperaturas')}>
                      <WorkspacePremiumIcon className='color-icon-menu'/>
                      <span className='spacing'></span> Grafico Temperaturas <BarChartIcon /> </p>
                  </div>
                  
                  <div className='' onClick={toggleDrawer('right', false)}>
                    <p
                      className={PageSelcected==='hidric'?'menu-item selected-item':'menu-item'}
                      onClick={()=>navigate('/hidric')}>
                      <BarChartIcon className='color-icon-menu'/>
                      <span className='spacing'></span> Grafico Hidrico 💧 </p>
                  </div>
                  <div className="" onClick={toggleDrawer("right", false)}>
                    <p
                      className={PageSelcected === "/datosdecaldera" ? "menu-item selected-item" : "menu-item"}
                      onClick={() => navigate("/datosdecaldera")}>
                      {/* <DashboardIcon className="color-icon-menu" /> */}
                      <WorkspacePremiumIcon className="color-icon-menu" />
                      <span className="spacing"></span> Grafico Caldera <BarChartIcon />
                    </p>
                  </div>
                </>:null
              }
              
                {/* Tabla indicadores crud */}
                {
                  aut_tablaindicadores.includes(LoginState.rol)?
                  <div className='' onClick={toggleDrawer('right', false)}>
                    <p
                      className={PageSelcected==='tablacrud'?'menu-item selected-item':'menu-item'}
                      onClick={()=>navigate('/tablacrud')}>
                      <BarChartIcon className='color-icon-menu'/>
                      <span className='spacing'></span> Indicadores Faena </p>
                  </div>:null
                }
              
              {/* ingreso de factuas */}
              
              {
                LoginState.rol_==="INGRESO-FACTURA"?<>
                  
                    <div className='' onClick={toggleDrawer('right', false)}>
                      <p
                        className={PageSelcected==='ifactura'?'menu-item selected-item':'menu-item'}
                        onClick={()=>navigate('/ifactura')}>
                      <ListAltIcon className='color-icon-menu'/>
                      <span className='spacing'></span> Ingreso Facturas</p>
                    </div>
                    <div className='' onClick={toggleDrawer('right', false)}>
                      <p
                        className={PageSelcected==='chartfactura'?'menu-item selected-item':'menu-item'}
                        onClick={()=>navigate('/chartfactura')}>
                      <BarChartIcon className='color-icon-menu'/>
                      <span className='spacing'></span> Grafico Facturas</p>
                    </div>
                    <div className='' onClick={toggleDrawer('right', false)}>
                      <p
                        className={PageSelcected==='viewfactura'?'menu-item selected-item':'menu-item'}
                        onClick={()=>navigate('/viewfactura')}>
                      <PlagiarismOutlinedIcon className='color-icon-menu'/>
                      <span className='spacing'></span> Ver Facturas</p>
                    </div>
                    <div className='' onClick={toggleDrawer('right', false)}>
                      <p
                        className={PageSelcected==='ingreso_temp'?'menu-item selected-item':'menu-item'}
                        onClick={()=>Make_a_reprot_facturas()}>
                        <FileOpenIcon className='color-icon-menu'/>
                        <span className='spacing'></span> Reporte Facturas</p>
                    </div>
                  </>
                  :null
              }
              
              {
                aut_tablaindicadores.includes(LoginState.rol)?null:
                IsMonitorFijo?null:
                  <>
                    {
                      (LoginState.area).includes('IQF')?null:
                      <>
                        <div className='' onClick={toggleDrawer('right', false)}>
                          <p
                            className={PageSelcected==='electric'?'menu-item selected-item':'menu-item'}
                            onClick={()=>navigate('/electric')}>
                            <BarChartIcon className='color-icon-menu'/>
                            <span className='spacing'></span> Grafico Electrico ⚡ </p>
                        </div>
                        <div className='' onClick={toggleDrawer('right', false)}>
                          <p
                            className={PageSelcected==='temperaturas'?'menu-item selected-item':'menu-item'}
                            onClick={()=>navigate('/temperaturas')}>
                            <BarChartIcon className='color-icon-menu'/>
                            <span className='spacing'></span> Temperatura IQF 🌡 </p>
                        </div>
                        <div className="" onClick={toggleDrawer("right", false)}>
                          <p
                            className={PageSelcected === "/v2/temperaturas" ? "menu-item selected-item" : "menu-item"}
                            onClick={() => navigate("/v2/temperaturas")}>
                            {/* <DashboardIcon className="color-icon-menu" /> */}
                            <WorkspacePremiumIcon className="color-icon-menu" />
                            <span className="spacing"></span>Temperaturas Planta <BarChartIcon />
                          </p>
                        </div>
                        <div className="" onClick={toggleDrawer("right", false)}>
                          <p
                            className={PageSelcected === "/v2/pozos" ? "menu-item selected-item" : "menu-item"}
                            onClick={() => navigate("/v2/pozos")}>
                            {/* <DashboardIcon className="color-icon-menu" /> */}
                            <WorkspacePremiumIcon className="color-icon-menu" />
                            <span className="spacing"></span> Pozos <BarChartIcon />
                          </p>
                        </div>

                        <div className='' onClick={toggleDrawer('right', false)}>
                          <p
                            className={PageSelcected==='hidric'?'menu-item selected-item':'menu-item'}
                            onClick={()=>navigate('/hidric')}>
                            <BarChartIcon className='color-icon-menu'/>
                            <span className='spacing'></span> Grafico Hidrico 💧 </p>
                        </div>
                      </>
                      
                    }
                    {
                      LoginState.area.includes('IQF')?
                      <div className='' onClick={toggleDrawer('right', false)}>
                        <p
                          className={PageSelcected==='ctrl_temp'?'menu-item selected-item':'menu-item'}
                          onClick={()=>navigate('/ctrl_temp')}>
                          <BarChartIcon className='color-icon-menu'/>
                          <span className='spacing'></span> Grafico Temperaturas 🌡</p>
                      </div>
                      :null
                    }
                    

                    {
                      LoginState.area.includes('IQF')?
                      <div className='' onClick={toggleDrawer('right', false)}>
                        <p
                          className={PageSelcected==='ingreso_temp'?'menu-item selected-item':'menu-item'}
                          onClick={()=>Make_a_reprot_tempsPlanta()}>
                          <FileOpenIcon className='color-icon-menu'/>
                          <span className='spacing'></span> Reporte 🌡</p>
                      </div>
                      :null
                    }
                    
                  </>
              }
              {
                (LoginState.rol).startsWith('dmatus')?<>
                <div className='' onClick={toggleDrawer('right', false)}>
                  <p
                    className={PageSelcected==='ctrl_temp'?'menu-item selected-item':'menu-item'}
                    onClick={()=>navigate('/ctrl_temp')}>
                    <BarChartIcon className='color-icon-menu'/>
                    <span className='spacing'></span> Grafico Temperaturas 🌡</p>
                </div>
                <div className='' onClick={toggleDrawer('right', false)}>
                    <p
                      className={PageSelcected==='ingreso_temp'?'menu-item selected-item':'menu-item'}
                      onClick={()=>Make_a_reprot_tempsPlanta()}>
                      <FileOpenIcon className='color-icon-menu'/>
                      <span className='spacing'></span> Reporte 🌡</p>
                  </div>
                </>
                :null
              }
              {
                (LoginState.rol).startsWith('vgomez')?
                  <div className='' onClick={toggleDrawer('right', false)}>
                    <p
                      className={PageSelcected==='accesos'?'menu-item selected-item':'menu-item'}
                      onClick={()=>navigate('/accesos')}>
                      <FactCheckOutlinedIcon className='color-icon-menu'/>
                      <span className='spacing'></span> Control Acceso <RuleIcon/></p>
                  </div>:null
              }
              {
                LoginState.rol==="CALDERA"?
                  <div className='' onClick={toggleDrawer('right', false)}>
                    <p
                      className={PageSelcected==='icaldera'?'menu-item selected-item':'menu-item'}
                      onClick={()=>navigate('/icaldera')}>
                    <ListAltIcon className='color-icon-menu'/>
                    <span className='spacing'></span> Ingreso Datos Caldera</p>
                  </div>
                :null
              }
              {
                LoginState.rol==='cjimenez@ariztia.com'?
                  <div className='' onClick={toggleDrawer('right', false)}>
                    <p
                      className={PageSelcected==='ssggpersonal'?'menu-item selected-item':'menu-item'}
                      onClick={()=>navigate('/ssggpersonal')}>
                    <ListAltIcon className='color-icon-menu'/>
                    <span className='spacing'></span> Monitor SSGG</p>
                  </div>
                :null
              }
              {
                LoginState.rol==='cjimenez@ariztia.com'?
                  <div className='' onClick={toggleDrawer('right', false)}>
                    <p
                      className={PageSelcected==='riless'?'menu-item selected-item':'menu-item'}
                      onClick={()=>navigate('/riless')}>
                    <ListAltIcon className='color-icon-menu'/>
                    <span className='spacing'></span> Monitor Riles</p>
                  </div>
                :null
              }
            </>
          }

          <br/>

          <hr />
          <p className="menu-item" onClick={() => HandleCloseSession()}>
            {/* <OutputOutlinedIcon className="color-icon-menu" /> */}
            <LogoutIcon className="color-icon-menu" />
            <span className="spacing"></span> Salir
          </p>
          <div className="menu-footer-version">
            <small>version {VERSION}</small>
          </div>
        </div>
      </Drawer>
      {
        IsModalElectric?<ModalElectricFile setIsModalElectric={setIsModalElectric}/>:null
      }
    </>
  );
};
